@import '~antd/dist/antd.css';
@import '~farmx-web-ui/dist/index.css';

/* override ant changes from 3.x to 4.x */
.ant-btn {
  padding: 0 15px;
}

.ant-btn-background-ghost.ant-btn-link.ant-btn-link {
  color: #555;
}

html, body, #root {
  width: 100%;
  height: 100%;
  min-height: -webkit-fill-available;
}

#root {
  display: flex;
}

body {
  margin: 0;
  overflow:hidden;
}

.main-menu a {
  color: white;
}

*:focus {
  /*
  https://www.eventbrite.com/engineering/how-to-fix-the-ugly-focus-ring-and-not-break-accessibility-in-react/
  outline: 2px solid #4d90fe;
  */
  outline: none;
}

.layout {
  flex: 1 1;
  overflow: hidden;
}

.content-container {
  padding: '0 0px';
  display: flex;
  flex: 1;
  overflow: hidden;
}

.column {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
}

.layout header {
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  background-color: rgb(255, 255, 255);
  border-bottom: 1px solid #ddd;
}

.layout .main-menu {
  line-height: 50px;
}

.layout .logo {
  padding-bottom: 8px;
  padding-right: 10px;
}

.header-logo {
  vertical-align: middle;
  height: 26px;
  width: 68px;
}

.message-container {
  flex-direction: column;
  border-radius: 5px;
  background-color: #fff;
  padding: 2em;
  margin: 2em 10%;
}

.padded {
  padding: 10px 20px;
}

.padded-margin {
  margin: 10px 20px;
}

.ant-page-header {
  padding: 12px 0px;
}

.ant-divider-horizontal {
  margin: 12px 0px;
}

.ant-menu-submenu-title span svg,
.ant-dropdown-menu-item a svg {
  margin-bottom: -2px;
  margin-right: 10px;
}

.fa-spin svg {
  animation: fa-spin 2s infinite linear;
}

.ant-select-dropdown .rc-virtual-list-scrollbar {
  display: block !important;
}

@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

@media only screen and (max-width: 600px) {
  .content-container {
    padding: '0 0px';
  }

  .message-container {
    margin: 0em 0em;
    flex: 1 0;
    border-radius: 0px;
  }

  .layout header {
    padding: 0px 0px !important;
  }

  .header-logo {
    height: 30px;
    display: none;
  }

  .layout .logo {
    padding-right: 0px;
  }
}
