.scrollable-page {
    overflow-y: auto !important;
}

.event-list-container {
    display: flex;
    flex: 1 1;
    justify-content: center;
    align-items: center;
}

.event-list-container .ant-list-item {
    background-color: white;
}

.event-list {
    flex: 1 1;
    max-width: 1600px;
}

.event-list-filters {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: -10px;
}

.event-list-filters > div {
    margin-right: 10px;
    min-width: 120px;
    margin-top: 10px;
}

.event-list-filters > div:last-of-type {
    margin-right: 0px;
}

.event-description > span {
    margin-right: 10px;
}

.loading-container {
    width: 100%;
    height: 100%;
    text-align: center;
  }
