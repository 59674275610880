.settings-page {
    display: flex;
    flex: 1 1;
    padding-bottom: 10px;
}

.settings-menu {
    width: 250px;
    border-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.settings-body {
    flex: 1 1;
    background-color: white;
    padding: 20px;
    margin-left: 10px;
    border-radius: 5px;
}

.settings-form {
    max-width: 400px;
}

@media only screen and (max-width: 800px) {
    .settings-menu {
        width: 50px;
    }

    .settings-body {
        margin-left: 5px;
    }
}
